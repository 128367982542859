@import 'fonts';
@import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import 'button';
@import 'editor';
@import 'footer';
@import 'form';
@import 'img';
@import 'navigation';
@import 'reveal';
@import 'slider';


@import "./vendor/tiny-slider/tiny-slider.css";


@layer utilities {
  body {
    opacity: 0;
  }
}
