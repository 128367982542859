.editor

  @apply text-black tracking-wider
  .bg-primary &
    @apply text-white
  &.primary
    @apply text-primary
    .bg-primary &
      @apply text-white

  h3,
  h4
    @apply font-avenir_next
    @apply uppercase tracking-wider mt-12

  // .copy_sm
  //   h3,
  //   h4
  //     @apply text-headline_copy_sm_m md:text-headline_copy_t xl:text-headline_copy_d 2xl:text-headline_copy_w

  // .copy_sm
  //   h3,
  //   h4
  //     @apply text-headline_copy_m md:text-headline_copy_t xl:text-headline_copy_d 2xl:text-headline_copy_w


  p,
  ol,
  ul
    @apply mb-[1.4em]
    &:last-child
      @apply mb-0

  a
    @apply text-inherit underline underline-offset-4 decoration-1 decoration-white hover:decoration-inherit transition-colors
    .bg-primary &
      @apply decoration-primary hover:decoration-inherit

  .copy_lg
    a
      @apply underline-offset-8 decoration-2

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0

  ol
    @apply list-decimal ml-[1em]
    li
      @apply pl-4 mb-2


  &-drop-caps
    p:first-child:first-letter
      line-height: 0.5 !important
      font-family: "Freight-Display", serif !important
      @apply float-left text-[570%] mr-[0.1em] mt-[0.11em]

  @-moz-document url-prefix()
    &-drop-caps
      p:first-child:first-letter
        @apply text-[590%] mt-[0.05em]

  // &-drop-caps
  //   p:first-child:first-letter
  //     line-height: 0.5 !important
  //     // font-family: "Freight-Display", serif !important
  //     font-family: "Avenir-Next", serif !important
  //     @apply float-left text-[520%] -ml-[0.05em] mr-[0.1em] mt-[0.2em]

  // @-moz-document url-prefix()
  //   &-drop-caps
  //     p:first-child:first-letter
  //       @apply text-[500%] mt-[0.1em]
