.form
  input,
  textarea
    @apply outline-none
    @apply transition-colors active:bg-primary-l hover:bg-primary-l focus:bg-primary-l

  .uniform__potty,
  .form--hp
    @apply absolute -left-[1111rem] my-0

  .form--alert
    @apply text-error uppercase tracking-ultra
    @apply block pt-1 md:pt-2

  .form--actions
    text-align: center


  .form--item
    @apply my-4 md:my-6
    &.form--winelist
      @apply mb-10 md:mb-20

  .form--text
    @apply w-full border-b border-primary
    @apply uppercase tracking-ultra text-primary
    @apply py-1 md:py-2 px-2 md:px-4

  .form--checkbox
    label
      @apply uppercase tracking-ultra text-primary
    input
      @apply accent-primary

  .form--item-textarea
    label
      @apply uppercase tracking-ultra text-primary
      @apply block mb-2
  .form--textarea
    @apply border border-primary w-full
    @apply p-2 md:p-4 text-primary uppercase tracking-ultra
