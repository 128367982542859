.navigation

  &--trigger
    @apply w-16 h-16 fixed z-30 top-0 md:top-2 right-0 md:right-4
    z-index: 100
    border: none
    background: none
    padding: 0
    outline: none !important

    @apply text-white
    .navigation__active &
      @apply hover:text-white

    @apply transition-opacity opacity-100 duration-200
    .scrolling &
      @apply opacity-0

    &-deco-01,
    &-deco-02,
    &-deco-03
      content: ''
      @apply block absolute top-8 right-4 w-8 h-[2px] md:h-[3px] transition-all duration-200 origin-center
      @apply bg-black
      @apply origin-center

      .navigation__white &,
      .navigation__active &
        @apply bg-white

    &-deco-01
      @apply rotate-0 -translate-y-2
    &-deco-02
      @apply rotate-0
    &-deco-03
      @apply rotate-0 translate-y-2

    .navigation__active &
      &-deco-01
        @apply -rotate-45 translate-y-0
      &-deco-02
        @apply opacity-0
      &-deco-03
        @apply rotate-45 translate-y-0
