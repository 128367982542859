    @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=9b0c0f22-d5b0-49d6-a4d5-29a20e75c2ea&fontids=6149660");

@font-face {
  font-family: "Avenir-Next";
  src: url("/assets/fonts/6149660/c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format("woff2"), url("/assets/fonts/6149660/a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Freight-Display";
  src: url("/assets/fonts/8188297/2a29ef1c-17e1-41c0-9a11-cc4a52785a91.woff2") format("woff2"), url("/assets/fonts/8188297/a2897cfd-4f13-4cec-81cd-a33ba2dc49ed.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Freight-Display";
  src: url("/assets/fonts/8188327/0efdf1d3-1ed1-4c12-9884-f7aef5d6be70.woff2") format("woff2"), url("/assets/fonts/8188327/51e43ba3-c44a-4592-aae4-e8eee55a25e8.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Freight-Text";
  src: url("/assets/fonts/8188610/4916107e-55ab-4a94-8730-5585dc8191e6.woff2") format("woff2"), url("/assets/fonts/8188610/a5e0ed0b-2a55-41a0-b207-715a5c083315.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
