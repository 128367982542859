.reveal
  @apply opacity-0 transition-opacity duration-1000 ease-in-out

  &.reveal__show,
  .reveal__show &
    @apply opacity-100


h1.reveal,
h2.reveal
  @apply opacity-0 translate-y-6 md:translate-y-10 transition-all duration-1000 ease-in-out

  &.reveal__show,
  &.reveal__show
    @apply opacity-100 translate-y-0

