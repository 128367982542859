.button
  @apply font-avenir_next text-button_m md:text-button_t uppercase tracking-ultra transition-colors
  @apply bg-transparent text-primary hover:bg-primary hover:text-white hover:border-primary
  @apply border border-current px-8 md:px-12 pt-3.5 pb-3 md:pt-5 md:pb-4
  @apply inline-block whitespace-nowrap
  .editor &
    @apply bg-white text-primary hover:bg-primary hover:text-white hover:border-primary
    @apply no-underline hover:no-underline decoration-0

  .bg-dark &
    @apply bg-primary text-white hover:bg-white hover:text-primary hover:border-white


  .md\:columns-2 &
    @apply md:inline-block md:mt-12 md:-translate-y-5
